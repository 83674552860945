import request from '@/utils/request';
// 策略列表
export function liststrategies(id, data) {
  return request({
    url: `/strategy/liststrategies/${id}`,
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 克隆策略
export function clonestrategy(id, data) {
  return request({
    url: `/strategy/clonestrategy/${id}`,
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 删除策略
export function deletestrategy(id) {
  return request({
    url: `/strategy/deletestrategy/${id}`,
    method: 'get',
  });
}
// 编辑策略
export function updatestrategy(id, data) {
  return request({
    url: `/strategy/updatestrategy/${id}`,
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 更新广告策略状态
export function updatestrategystatus(id, data) {
  return request({
    url: `/strategy/updatestrategystatus/${id}`,
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 获取当前广告账号下的广告系列
export function getCampaigns(data) {
  return request({
    url: `/fbConnection/campaigns`,
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 新增策略
export function addStrategy(data) {
  return request({
    url: `/strategy/addStrategy`,
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 获取规则字典
export function getruledicts() {
  return request({
    url: `/adrules/getruledicts`,
    method: 'post',
  });
}
// 设置规则
export function addrule(data) {
  return request({
    url: `/adrule/addrule`,
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 获取用户信息
export function getUsers() {
  return request({
    url: `/businessManager/users`,
    method: 'get',
  });
}
// 获取规则列表
export function getrulelist(data) {
  return request({
    url: `/adrule/getrulelist`,
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 删除规则
export function deleterule(id) {
  return request({
    url: `/adrule/deleterule/${id}`,
    method: 'get',
  });
}
// 复制规则
export function copyRule(id) {
  return request({
    url: `/adrule/replicate/${id}`,
    method: 'post',
  });
}
// 关闭规则
export function disableRule(data) {
  return request({
    url: `/adrule/disable`,
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 激活规则
export function enableRule(data) {
  return request({
    url: `/adrule/enable`,
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 根据规则id获取规则
export function getRuleById(data) {
  return request({
    url: `/adrule/getbyid`,
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 编辑规则
export function updaterule(data) {
  return request({
    url: `/adrule/updaterule`,
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 添加模板策略
export function addtemplate(data) {
  return request({
    url: `/strategy/addtemplate`,
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 规则日志
export function getRuleLogList(data) {
  return request({
    url: `/adrule/getRuleLogList`,
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 获取可执行的自动化规则方案
export const getRules = (params) =>
  request({
    method: 'get',
    url: `auto/api/rules`,
    params,
    baseURL: process.env.VUE_APP_BASE_GATEWAY,
  });
// 执行自动化规则
export const automation = (params) =>
  request({
    method: 'get',
    url: 'auto/api/task/run/plural',
    params,
    baseURL: process.env.VUE_APP_BASE_GATEWAY,
    timeout: 0,
  });
