import request from '@/utils/request';
import store from "@/store";

const GET = (url, params, config) => request({
    url: url,
    method: 'get',
    params,
    ...config
})
const POST = (url, data, config = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
}) => request({
    url: url,
    method: 'post',
    ...config,
    data,
    transformRequest: [function (data) {
        return JSON.stringify(data)
    }]
})

export const getAlertNames = () => POST("/alert/alertNames");

export const getQueryItems = data => POST("/alert/queryItems", data);

export const getReport = (data, adAccountId) => POST("/ads/report", {
    ...data,
    account_id: adAccountId
}, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'withoutFbLogin': true,
        'adAccountId': adAccountId
    },
});

export const pausedAd = (data, adAccountId) => POST("/ads/mutateStatus", {
    ...data,
    status: "PAUSED"
}, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'withoutFbLogin': true,
        'adAccountId': adAccountId
    },
});

export const activeAd = (data, adAccountId) => POST("/ads/mutateStatus", {
    ...data,
    status: "ACTIVE"
}, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'withoutFbLogin': true,
        'adAccountId': adAccountId
    },
});

export const pricingInfo = (data, adAccountId) => POST("/ads/pricingInfo", data, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'withoutFbLogin': true,
        'adAccountId': adAccountId
    },
});

export const mutatePricing = (data, adAccountId) => POST("/ads/mutatePricing", data, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'withoutFbLogin': true,
        'adAccountId': adAccountId
    },
});

export const accountQuery = data => POST("/alert/accountQuery", data);

export const changeProcessed = params => GET("/alert/alertProcessed", params)

export const getLoginList = params => GET("/gimpUser/allAdAccounts", params)
// https://gateway.giikin.com
// tiktok
const tiktokBase = process.env.VUE_APP_TIKTOK_BASE;
// const token = store.getters.token
// const token = 'd5c0654935ca32fb6d4cfb7ab9a021a9'
// 查询广告系列预算
export const getCampaignBudget = params => GET(`/api/tiktok/campaign/budget/get?_token=${store.getters.token}`, params, {
    baseURL: tiktokBase
})

// 修改广告系列预算
export const changeCampaignBudget = params => GET(`/api/tiktok/campaign/budget/adjust?_token=${store.getters.token}`, params, {
    baseURL: tiktokBase
})

// 打开/关闭广告系列
export const switchCampaign = params => GET(`/api/tiktok/campaign/budget/switch?_token=${store.getters.token}`, params, {
    baseURL: tiktokBase
})

// 查询广告组当前预算
export const getAdsetBudget = params => GET(`/api/tiktok/adGroup/budget/get?_token=${store.getters.token}`, params, {
    baseURL: tiktokBase
})

// 修改广告组预算
export const changeAdsetBudget = params => GET(`/api/tiktok/adGroup/budget/adjust?_token=${store.getters.token}`, params, {
    baseURL: tiktokBase
})

// 打开/关闭广告组
export const switchAdset = params => GET(`/api/tiktok/adGroup/budget/switch?_token=${store.getters.token}`, params, {
    baseURL: tiktokBase
})

// line
// 打开/关闭广告组
export const switchLineCampaign = params => GET(`/api/line/campaign/budget/switch?_token=${store.getters.token}`, params, {
    baseURL: tiktokBase
})